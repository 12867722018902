<template>
  <p
    v-if="
      index === 0 ||
      currentNationality !== prevNationality ||
      currentStage !== prevStage
    "
    class="inline-flex w-full gap-1 px-2 py-4 text-lg font-semibold uppercase text-primary @lg:px-0"
    data-testid="AuctionListItemNationality"
  >
    <span>{{ `${$t(STAGE_MAP[currentStage])} ` }}</span>
    <span v-if="currentStage === 'IN_PREPARATION'">{{ `- ` }}</span>
    <span v-if="currentStage !== 'FINISHED'">{{ currentNationality }}</span>
  </p>
</template>

<script setup lang="ts">
interface Props {
  currentNationality: string
  prevNationality?: string
  index: number
  currentStage: string
  prevStage?: string
}

defineProps<Props>()

const STAGE_MAP = {
  ONLINE: '',
  IN_PREPARATION: 'auction-list.in-preparation',
  FINISHED: 'auction-list.finished'
}
</script>
